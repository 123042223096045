export const LOGIN_SUCCESS = 'LOGIN_SUCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const AUTH_REQUEST_START = 'AUTH_REQUEST_START';
export const AUTH_REQUEST_END = 'AUTH_REQUEST_END';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILED = 'FETCH_BALANCE_FAILED';
export const MAIL_VERIFIED_REQUEST_SUCCESS = 'MAIL_VERIFIED_REQUEST_SUCCESS';
export const MAIL_VERIFIED_REQUEST_FAILED = 'MAIL_VERIFIED_REQUEST_FAILED';
export const UPDATE_SCORECARD = 'UPDATE_SCORECARD';
export const OPEN_DEPOSIT_MODAL = 'OPEN_DEPOSIT_MODAL';
export const OPEN_WITHDRAW_MODAL = 'OPEN_WITHDRAW_MODAL';
